<template lang="pug">
v-container.ma-0.pa-0.landing
    v-layout.section-0.l-header(row wrap)
        .btns.btns-right.pr-3
            v-btn.primary(small @click="$router.push({path: '/login'})") Sign in
            v-btn.primary(small href="https://wa.me/573113898661") Contact
            v-btn.primary(small @click="$router.push({path: '/register'})") Request your networks
    v-layout.section-1(row wrap)
        v-flex.text-xs-center.mx-auto.pt-0(xs12)
            a.cur-p(href="https://wa.me/573113898661")
                img.logo-s1( alt="wappid - multilevel system to win elections via whatsApp" src="/public/img/landing/en-logo-s1.png")
        v-flex.login-text-landing(v-if="activeNetwork" xs12 v-html="logintext")
    v-layout.section-2.blue-gradient.pa-3(row wrap)
        v-flex.mx-auto.max-width(xs12)
            v-layout(row wrap)
                v-flex.desc(xs12)
                    v-layout.px-4(row wrap)
                        v-flex.pa-0.ma-0(xs12 sm6)
                            img.full-width(src="/public/img/landing/s2-desc.png")
                        v-flex.pa-0.ma-0(xs12 sm6 align-stretch)
                            p.full-width(style="height:100%;")
                                b Wappid 
                                | is a digital platform used for creating and microsegmenting networks, using WhatsApp to attract and motivate activists around a common cause
                v-flex.desc-2.mt-3(xs12)
                    v-layout(row wrap align-center)
                        v-flex(xs12 sm6)
                            v-layout.px-4(row wrap)
                                v-flex.s2-img1(xs3)
                                    img.full-width(src="/public/img/landing/s2-n1.png")
                                v-flex(xs9)
                                    b.l-green Wappid 
                                    | is a platform built from neuroscience 
                                v-flex(xs9)
                                    p.font-italic.text-xs-center A brain-friendly solution that aims to conquer minds and hearts  
                                v-flex.s2-img2(xs3)
                                    img.full-width(src="/public/img/landing/s2-n2.png")
                        v-flex(xs12 sm6)
                            div.whatsapp-box.my-4.px-4.mx-4
                                v-layout.py-2(row wrap align-center)
                                    v-flex.text-sm-right(xs2 sm3)
                                        img.full-width(src="/public/img/landing/s2-wp.png")
                                    v-flex.text-sm-center(xs10 sm9)
                                        h4 Why WhatsApp?
                                ul.pl-3.pb-3
                                    li With over 2 billion users, it is the second most used social network in the world, only behind Facebook.
                                    li Over a third part of the world's population uses this platform every day.
                                    li WhatsApp is secure and trustworthy because those we talk to are friends and/or family, and we believe in them.
                                    li It has 3 synergies: credibility, congruence, and authenticity.
    v-layout.section-3.text-xs-center.pa-4(row wrap align-center)
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-4(src="/public/img/landing/en-logo-s3.png")
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-5(src="/public/img/landing/gpt.png")
    v-layout.section-6(row wrap)
        v-flex.desc1.pa-4(xs12 sm6)
            h3.l-secondary Activism and Militancy
            p.my-3
                b.l-purple Wappid 
                | operates with 6 levels of activism and militancy to build winning political campaigns
            img.full-width(src="/public/img/landing/en-s6-desc1.png")
        v-flex.desc2.pa-4(xs12 sm6)
            h3.l-secondary Microsegmentation
            img.full-width.my-4(src="/public/img/landing/en-s6-desc2.png")
            p
                b.l-purple Wappid 
                span.l-primary.font-italic finds your most passionate activists 
    v-layout.section-7(row wrap)
        v-flex.mx-auto.max-width.pa-4.my-4(xs12)
            h3.l-secondary Gamification
            v-layout(row wrap)
                v-flex(xs12)
                    ul.ml-4
                        li 
                            b.l-purple Wappid 
                            | uses specific game mechanisms to improve: 
                            ul.ml-4.my-2.mb-3.subul
                                li Motivation  
                                li Concentration  
                                li Effort  
                                li Loyalty
                            | Create engaging experiences that inspire actions and involve people with the cause.
                        li.my-3 
                            b.l-purple Wappid 
                            | motivates members of your support networks by reaching goals and recognition in their community.
                v-flex(xs12)
                    p
                        b.l-purple Wappid 
                        | organizes, structures, and measures political activism through playful activities that lead to action.
                    ul.ml-3.l-secondary
                        li Challenges
                        li Badges
                        li Rewards
                        li Rankings (positions)
    v-layout.section-8(row wrap)
        v-flex.mx-auto.max-width.pa-4.mt-4(xs12)
            h3 
                span.l-purple Wappid 
                span.l-primary is distinguished by:
            img.full-width.my-5.s8-img(src="/public/img/landing/en-s8-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step.step1
                            h5 Focus
                            p Focus your operation on WhatsApp; in Wappid, everything happens via WhatsApp.
                    v-flex(xs12 sm6 md4)
                        div.step.step2
                            h5 Authenticity
                            p Create authentic networks of people connected around a cause
                    v-flex(xs12 sm6 md4)
                        div.step.step3
                            h5 Integrity
                            p Provides feedback and continuously audits itself
                    v-flex(xs12 sm6 md4)
                        div.step.step4
                            h5 Permeability 
                            p Generates activism and militancy building leadership  
                    v-flex(xs12 sm6 md4)
                        div.step.step5
                            h5 Action
                            p Identify, motivate, and keep militants always in action
                    v-flex(xs12 sm6 md4)
                        div.step.step6
                            h5 Utility
                            p Offers real-time campaign status information, 24/7
                    v-flex(xs12 sm6 md4)
                        div.step.step7
                            h5 Trust
                            p Creates quality communication
    v-layout.section-9(row wrap)
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.l-secondary.mt-4 Activating Our Wappid Network
            img.full-width.my-5.s9-img(src="/public/img/landing/en-s9-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#33C0CA;")
                            h5(style="color:#33C0CA;") 1. Create your network
                            p Microsegment and build your own network  
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#0D9192;")
                            h5(style="color:#0D9192;") 2. Integrate your team
                            p Form your team of leaders, train them to use the network 
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#79C14F;")
                            h5(style="color:#79C14F;") 3. Promote
                            p Spread your network among your supporters and followers
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#ACD14B;")
                            h5(style="color:#ACD14B;") 4. Recommend
                            p Recommend one by one, invite, share, and expand your network
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#D1DE3B;")
                            h5(style="color:#D1DE3B;") 5. Communicate
                            p Create content, direct specific messages
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#FEBD31;")
                            h5(style="color:#FEBD31;") 6. Gamify
                            p Intensify the experience, challenge, reward, recognize people. Generate positive addiction  
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#F36D2A;")
                            h5(style="color:#F36D2A;") 7. Audit
                            p Audit your goals, prioritize resources, organize your action every day
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#EE352A;")
                            h5(style="color:#EE352A;") 8. Feedback
                            p Feed back the process, improve every day  

        v-flex.mx-auto.max-width.pa-4(xs12)
            h4.l-secondary How does Wappid work?
            p.l-primary.mt-3
                b.l-secondary Wappid 
                | creates networks of authentic conviction around a cause, people who join voluntarily, by invitation, one by one. We start from a support network close to our candidate, which naturally expands as it multiplies its range of action. Through microsegmentation, we identify who makes up the network and establish efficient communications directed to each segment; In addition, we identify, train, and motivate passionate leaders, activists, and militants who remain in action for the whole campaign.
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.wp-title
                img.mr-1(src="/public/img/landing/s9-wp.png")
                span.ml-1.font-italic.l-green “Everything happens via WhatsApp”
            p.l-primary.mt-2.font-italic: b Microsegmentation, networks, quality communication, committed leadership that becomes activists and militants in action, all translate into effective votes.
    v-layout.section-0.blue-gradient(row wrap)
        v-flex.px-4.text-xs-center.pt-4.max-width.mx-auto(xs12)
            a(@click="$router.push({path:'/terms'})") Terms and Conditions and Privacy Policies
        v-flex.px-4.text-xs-center.pb-4.max-width.mx-auto(xs12)
            span.white--text Wappid - The Customized Technological Solution to Win Elections via WhatsApp
        v-flex.text-xs-center.mx-auto.py-2(xs12 style="margin-bottom:30px;")
            img(style="max-width:250px;" src="/public/img/landing/en-logo-footer.png")
</template>

<script>
    export default {
        props:{
            activeNetwork: null,
            logintext: null
        },
        metaInfo (){
            return {
                title: this.$t('Wappid')
            }
        },
    }
</script>