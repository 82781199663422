<template lang="pug">
div
    loader(v-if="$root.loader")
    template(v-else)
        template(v-if="$root.currentLanguage == 'pt'")
            landing-pt(:active-network="activeNetwork" :logintext="logintext")
        template(v-if="$root.currentLanguage == 'en'")
            landing-en(:active-network="activeNetwork" :logintext="logintext")
        template(v-else)
            landing-es(:active-network="activeNetwork" :logintext="logintext")
</template>

<script>

    import LandingEs from 'pages/landing/i18n/LandingEs'
    import LandingPt from 'pages/landing/i18n/LandingPt'
    import LandingEn from 'pages/landing/i18n/LandingEn'

    import auth from 'mixins/auth'
    export default {
        mixins: [auth],
        metaInfo (){
            return {
                title: this.$t('Wappid')
            }
        },
        components:{
            'landing-es': LandingEs,
            'landing-pt': LandingPt,
            'landing-en': LandingEn
        }
    }
</script>