<template lang="pug">
v-container.ma-0.pa-0.landing
    v-layout.section-0.l-header(row wrap)
        .btns.btns-right.pr-3
            v-btn.primary(small @click="$router.push({path: '/login'})") Ingresar
            v-btn.primary(small href="https://wa.me/573113898661") Contacto
            v-btn.primary(small @click="$router.push({path: '/register'})") Solicitar redes
    v-layout.section-1(row wrap)
        v-flex.text-xs-center.mx-auto.pt-0(xs12)
            a.cur-p(href="https://wa.me/573113898661")
                img.logo-s1( alt="wappid - sistema multinivel para ganar las elecciones por whatsapp" src="/public/img/landing/logo-s1.png")
        v-flex.login-text-landing(v-if="activeNetwork" xs12 v-html="logintext")
    v-layout.section-2.blue-gradient.pa-3(row wrap)
        v-flex.mx-auto.max-width(xs12)
            v-layout(row wrap)
                v-flex.desc(xs12)
                    v-layout.px-4(row wrap)
                        v-flex.pa-0.ma-0(xs12 sm6)
                            img.full-width(src="/public/img/landing/s2-desc.png")
                        v-flex.pa-0.ma-0(xs12 sm6 align-stretch)
                            p.full-width(style="height:100%;")
                                b Wappid 
                                | es una plataforma digital de creación y microsegmentación de redes, que utiliza Whatsapp para atraer y motivar la participación de activistas en torno a una causa común
                v-flex.desc-2.mt-3(xs12)
                    v-layout(row wrap align-center)
                        v-flex(xs12 sm6)
                            v-layout.px-4(row wrap)
                                v-flex.s2-img1(xs3)
                                    img.full-width(src="/public/img/landing/s2-n1.png")
                                v-flex(xs9)
                                    b.l-green Wappid 
                                    | es una plataforma construida desde las neurociencias
                                v-flex(xs9)
                                    p.font-italic.text-xs-center Es una solución cerebro-amigable, conquista cerebros y corazones
                                v-flex.s2-img2(xs3)
                                    img.full-width(src="/public/img/landing/s2-n2.png")
                        v-flex(xs12 sm6)
                            div.whatsapp-box.my-4.px-4.mx-4
                                v-layout.py-2(row wrap align-center)
                                    v-flex.text-sm-right(xs2 sm3)
                                        img.full-width(src="/public/img/landing/s2-wp.png")
                                    v-flex.text-sm-center(xs10 sm9)
                                        h4 ¿Por qué WhatsApp?
                                ul.pl-3.pb-3
                                    li Con más de 2 mil millones de usuarios es la segunda red  social más usada en el mundo, sólo después de Facebook.
                                    li Más de 80 millones de usuarios en México.
                                    li "WhatsApp es seguro y confiabe porque con quienes hablamos son amigos y/o familiares, y les creemos".
                                    li Posee 3 sinergias: credibilidad, congruencia y autenticidad.
    v-layout.section-3.text-xs-center.pa-4(row wrap align-center)
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-4(src="/public/img/landing/logo-s3.png")
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-5(src="/public/img/landing/gpt.png")
    v-layout.section-6(row wrap)
        v-flex.desc1.pa-4(xs12 sm6)
            h3.l-secondary Activismo y militancia
            p.my-3
                b.l-purple Wappid 
                | opera con 6 niveles de activismo y militancia  para construir campañas políticas ganadoras
            img.full-width(src="/public/img/landing/s6-desc1.png")
        v-flex.desc2.pa-4(xs12 sm6)
            h3.l-secondary Microsegmentación
            img.full-width.my-4(src="/public/img/landing/s6-desc2.png")
            p
                b.l-purple Wappid 
                span.l-primary.font-italic es el mejor sistema de microsegmentación vía WhatsApp en el mundo
    v-layout.section-7(row wrap)
        v-flex.mx-auto.max-width.pa-4.my-4(xs12)
            h3.l-secondary Gamificación
            v-layout(row wrap)
                v-flex(xs12)
                    ul.ml-4
                        li 
                            b.l-purple Wappid 
                            | emplea mecanismos propios de los juegos con  el fin de potenciar: 
                            ul.ml-4.my-2.mb-3.subul
                                li Motivación  
                                li Concentración  
                                li Esfuerzo  
                                li Fidelización
                            | Creando experiencias atractivas que inspiran a la acción e involucran a las personas con la causa.
                        li.my-3 
                            b.l-purple Wappid 
                            | motiva a los integrantes de sus redes de apoyo mediante el logro de objetivos y reconocimiento en su comunidad.
                v-flex(xs12)
                    p
                        b.l-purple Wappid 
                        | organiza, estructura y mide el activismo político  por medio de actividades lúdicas que llevan a la acción.
                    ul.ml-3.l-secondary
                        li Retos
                        li Insignias
                        li Recompensas
                        li Rankings (Posiciones)
    v-layout.section-8(row wrap)
        v-flex.mx-auto.max-width.pa-4.mt-4(xs12)
            h3 
                span.l-purple Wappid 
                span.l-primary se distingue por:
            img.full-width.my-5.s8-img(src="/public/img/landing/s8-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step.step1
                            h5 ENFOQUE
                            p Centra su operación en WhatsApp “En Wappid todo pasa x WhatsApp”
                    v-flex(xs12 sm6 md4)
                        div.step.step2
                            h5 AUTENTICIDAD
                            p Crea auténticas redes de personas conectadas en torno a una causa
                    v-flex(xs12 sm6 md4)
                        div.step.step3
                            h5 INTEGRIDAD
                            p Se retroalimenta y audita  a sí mismo de forma permanente
                    v-flex(xs12 sm6 md4)
                        div.step.step4
                            h5 PUNTUAL
                            p Genera activismo y militancia, construyendo liderazgo
                    v-flex(xs12 sm6 md4)
                        div.step.step5
                            h5 ACCIÓN
                            p Identifica, motiva y mantiene en acción a activistas y militantes
                    v-flex(xs12 sm6 md4)
                        div.step.step6
                            h5 UTILIDAD
                            p Ofrece información del estado general de la campaña en tiempo real 24/7
                    v-flex(xs12 sm6 md4)
                        div.step.step7
                            h5 CONFIANZA
                            p Crea comunicación de calidad
    v-layout.section-9(row wrap)
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.l-secondary.mt-4 ¡Enciende Wappid!
            img.full-width.my-5.s9-img(src="/public/img/landing/s9-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#33C0CA;")
                            h5(style="color:#33C0CA;") Paso 1. Crear tu red
                            p Microsegmenta, Construye tu Red
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#0D9192;")
                            h5(style="color:#0D9192;") Paso 2. Integra tu equipo
                            p Conforma tu equipo de líderes, capacítalo, enséñale a usar la Red
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#79C14F;")
                            h5(style="color:#79C14F;") Paso 3. Promueve
                            p Lanza tu Red entre tus seguidores y activistas
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#ACD14B;")
                            h5(style="color:#ACD14B;") Paso 4. Refiere
                            p Refiere uno a uno, invita, comparte, Crece tu Red
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#D1DE3B;")
                            h5(style="color:#D1DE3B;") Paso 5. Comunica
                            p Crea contenidos, Dirige mensajes específicos
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#FEBD31;")
                            h5(style="color:#FEBD31;") Paso 6. Gamifica
                            p Intensifica la experiencia, reta, gratifica, distingue, Crea adicción
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#F36D2A;")
                            h5(style="color:#F36D2A;") Paso 7. Audita
                            p Audita tus metas, prioriza recursos, enfoca tu acción día a día
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#EE352A;")
                            h5(style="color:#EE352A;") Paso 8. Haz Feedback
                            p Retroalimenta el proceso, Mejora continua

        v-flex.mx-auto.max-width.pa-4(xs12)
            h4.l-secondary ¿Cómo funciona Wappid?
            p.l-primary.mt-3
                b.l-secondary Wappid 
                | crea redes de auténtica convicción en torno a una causa, personas que se suman por voluntad propia, por invitación uno a uno. Partimos de una red de apoyo cercana al candidato  que se amplifica de forma natural multiplicando su radio de acción. A través  de  microsegmentación identificamos quiénes integran la red y establecemos comunicaciones  eficientes direccionadas para cada segmento; además, identificamos, formamos y motivamos  líderes, activistas y militantes por pasión que se mantienen en acción en pro de la campaña.
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.wp-title
                img.mr-1(src="/public/img/landing/s9-wp.png")
                span.ml-1.font-italic.l-green “Todo pasa por WhatsApp”
            p.l-primary.mt-2.font-italic: b Microsegmentación, redes, comunicaciones de calidad, líderes comprometidos que se convierten en activistas y militantes en acción, todo ello traducido a votos efectivos.
    v-layout.section-0.blue-gradient(row wrap)
        v-flex.px-4.text-xs-center.pt-4.max-width.mx-auto(xs12)
            a(@click="$router.push({path:'/terms'})") Términos y condiciones y políticas de privacidad
        v-flex.px-4.text-xs-center.pb-4.max-width.mx-auto(xs12)
            span.white--text Wappid - La solución tecnológica a la medida para ganar elecciones por WhatsApp
        v-flex.text-xs-center.mx-auto.py-2(xs12 style="margin-bottom:30px;")
            img(style="max-width:250px;" src="/public/img/landing/logo-footer.png")
</template>

<script>
    export default {
        props:{
            activeNetwork: null,
            logintext: null
        },
        metaInfo (){
            return {
                title: this.$t('Wappid')
            }
        },
    }
</script>