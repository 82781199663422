<template lang="pug">
v-container.ma-0.pa-0.landing
    v-layout.section-0.l-header(row wrap)
        .btns.btns-right.pr-3
            v-btn.primary(small @click="$router.push({path: '/login'})") Entrar
            v-btn.primary(small href="https://wa.me/573113898661") Contato
            v-btn.primary(small @click="$router.push({path: '/register'})") Solicitar redes
    v-layout.section-1(row wrap)
        v-flex.text-xs-center.mx-auto.pt-0(xs12)
            a.cur-p(href="https://wa.me/573113898661")
                img.logo-s1( alt="wappid - sistema Multinivel para vencer eleicoes pelo whatsapp" src="/public/img/landing/pt-logo-s1.png")
        v-flex.login-text-landing(v-if="activeNetwork" xs12 v-html="logintext")
    v-layout.section-2.blue-gradient.pa-3(row wrap)
        v-flex.mx-auto.max-width(xs12)
            v-layout(row wrap)
                v-flex.desc(xs12)
                    v-layout.px-4(row wrap)
                        v-flex.pa-0.ma-0(xs12 sm6)
                            img.full-width(src="/public/img/landing/s2-desc.png")
                        v-flex.pa-0.ma-0(xs12 sm6 align-stretch)
                            p.full-width(style="height:100%;")
                                b Wappid 
                                | é uma plataforma digital de criação e microssegmentação de redes, que utiliza o WhatsApp para atrair e motivar a participação de ativistas em torno de uma causa comum
                v-flex.desc-2.mt-3(xs12)
                    v-layout(row wrap align-center)
                        v-flex(xs12 sm6)
                            v-layout.px-4(row wrap)
                                v-flex.s2-img1(xs3)
                                    img.full-width(src="/public/img/landing/s2-n1.png")
                                v-flex(xs9)
                                    b.l-green Wappid 
                                    | é uma plataforma construída a partir de neurociências
                                v-flex(xs9)
                                    p.font-italic.text-xs-center Uma solução amiga do cérebro, conquista cérebros e corações
                                v-flex.s2-img2(xs3)
                                    img.full-width(src="/public/img/landing/s2-n2.png")
                        v-flex(xs12 sm6)
                            div.whatsapp-box.my-4.px-4.mx-4
                                v-layout.py-2(row wrap align-center)
                                    v-flex.text-sm-right(xs2 sm3)
                                        img.full-width(src="/public/img/landing/s2-wp.png")
                                    v-flex.text-sm-center(xs10 sm9)
                                        h4 ¿Por qué WhatsApp?
                                ul.pl-3.pb-3
                                    li Com mais de 2 bilhões de usuários, é a segunda rede social mais utilizada no mundo, atrás apenas do Facebook.
                                    li Alcançando 94% dos brasileiros conectados à internet, o “Zap” é hoje a plataforma digital mais popular do Brasil.
                                    li "O WhatsApp é seguro e confiável porque aqueles com quem conversamos são amigos e/ou familiares e acreditamos neles".
                                    li Possui 3 sinergias: credibilidade, congruência e autenticidade.
    v-layout.section-3.text-xs-center.pa-4(row wrap align-center)
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-4(src="/public/img/landing/pt-logo-s3.png")
        v-flex.mx-auto.max-width(xs12)
            img.full-width.pa-5(src="/public/img/landing/gpt.png")
    v-layout.section-6(row wrap)
        v-flex.desc1.pa-4(xs12 sm6)
            h3.l-secondary Ativismo e militância
            p.my-3
                b.l-purple Wappid 
                | atua com 6 níveis de ativismo e militância para construir campanhas políticas vencedoras
            img.full-width(src="/public/img/landing/pt-s6-desc1.png")
        v-flex.desc2.pa-4(xs12 sm6)
            h3.l-secondary Microssegmentação
            img.full-width.my-4(src="/public/img/landing/pt-s6-desc2.png")
            p
                b.l-purple Wappid 
                span.l-primary.font-italic Wappid é o melhor sistema de micro segmentação via WhatsApp do mundo
    v-layout.section-7(row wrap)
        v-flex.mx-auto.max-width.pa-4.my-4(xs12)
            h3.l-secondary Gamificação
            v-layout(row wrap)
                v-flex(xs12)
                    ul.ml-4
                        li 
                            b.l-purple Wappid 
                            | usa mecanismos específicos do jogo para melhorar: 
                            ul.ml-4.my-2.mb-3.subul
                                li Motivação  
                                li Concentração  
                                li Esforço  
                                li Lealdade
                            | Criar experiências envolventes que inspirem ações e envolvam as pessoas com a causa.
                        li.my-3 
                            b.l-purple Wappid 
                            | motiva os membros de suas redes de apoio alcançando metas e reconhecimento em sua comunidade.
                v-flex(xs12)
                    p
                        b.l-purple Wappid 
                        | organiza, estrutura e mede o ativismo político por meio de atividades lúdicas que levam à ação.
                    ul.ml-3.l-secondary
                        li Retos
                        li Insígnia
                        li Recompensas
                        li Classificações (posições)
    v-layout.section-8(row wrap)
        v-flex.mx-auto.max-width.pa-4.mt-4(xs12)
            h3 
                span.l-purple Wappid 
                span.l-primary se distingue por:
            img.full-width.my-5.s8-img(src="/public/img/landing/pt-s8-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step.step1
                            h5 FOCO
                            p Foque sua operação no WhatsApp, no Wappid tudo acontece pelo WhatsApp
                    v-flex(xs12 sm6 md4)
                        div.step.step2
                            h5 AUTENTICIDADE
                            p criar redes autênticas de pessoas conectadas em torno de uma causa
                    v-flex(xs12 sm6 md4)
                        div.step.step3
                            h5 INTEGRIDADE
                            p Fornece feedback e se audita permanentemente
                    v-flex(xs12 sm6 md4)
                        div.step.step4
                            h5 CAPILARIDADE 
                            p Genera activismo y militancia construyendo liderazgo
                    v-flex(xs12 sm6 md4)
                        div.step.step5
                            h5 AÇÃO
                            p Identifica, motiva e mantém os militantes sempre em ação
                    v-flex(xs12 sm6 md4)
                        div.step.step6
                            h5 UTILIDADE
                            p Oferece informações sobre o status da campanha em tempo real, 24 horas por dia, 7 dias por semana
                    v-flex(xs12 sm6 md4)
                        div.step.step7
                            h5 CONFIANÇA
                            p Crea comunicação de qualidade
    v-layout.section-9(row wrap)
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.l-secondary.mt-4 ¡Ativando nossa rede de Wappid!
            img.full-width.my-5.s9-img(src="/public/img/landing/pt-s9-desc.png")
            div.steps
                v-layout(row wrap justify-center)
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#33C0CA;")
                            h5(style="color:#33C0CA;") 1. Crie sua rede
                            p Microssegmente e construa sua própria rede
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#0D9192;")
                            h5(style="color:#0D9192;") 2. Integre sua equipe
                            p Forme sua equipe de líderes, treine-os para usar a rede 
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#79C14F;")
                            h5(style="color:#79C14F;") 3. Promova
                            p Divulgue sua rede entre seus apoiadores e seguidores
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#ACD14B;")
                            h5(style="color:#ACD14B;") 4. Recomenda
                            p Recomende um por um, convide, compartilhe, aumente sua rede
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#D1DE3B;")
                            h5(style="color:#D1DE3B;") 5. Comunica
                            p Cria conteúdo, direciona mensagens específicas
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#FEBD31;")
                            h5(style="color:#FEBD31;") 6. Gamifica
                            p Intensifica a experiência, desafia, gratifica, reconhece as pessoas. Gera adiccao positiva
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#F36D2A;")
                            h5(style="color:#F36D2A;") 7. Audita
                            p Audite suas metas, prioriza recursos, organize sua ação todos os dias
                    v-flex(xs12 sm6 md4)
                        div.step(style="border-color:#EE352A;")
                            h5(style="color:#EE352A;") 8. Feedback
                            p Retroalimenta o processo, melhora a cada dia

        v-flex.mx-auto.max-width.pa-4(xs12)
            h4.l-secondary ¿Como funciona o Wappid?
            p.l-primary.mt-3
                b.l-secondary Wappid 
                | cria redes de convicção autêntica em torno de uma causa, pessoas que se unem por vontade própria, por convite, uma a uma. Partimos de uma rede de apoio próxima a nosso/a candidato/a que se amplia naturalmente ao multiplicar seu raio de atuação. Através da microssegmentação identificamos quem compõe a rede e estabelecemos comunicações eficientes direcionadas a cada segmento; Além disso, identificamos, treinamos e motivamos lideranças, ativistas e militantes apaixonados que permanecem em ação pela campanha.
        v-flex.mx-auto.max-width.pa-4(xs12)
            h3.wp-title
                img.mr-1(src="/public/img/landing/s9-wp.png")
                span.ml-1.font-italic.l-green “Tudo acontece pelo WhatsApp”
            p.l-primary.mt-2.font-italic: b Microssegmentação, redes, comunicação de qualidade, lideranças comprometidas que se tornam ativistas e militantes em ação, tudo isso se traduz em votos efetivos.
    v-layout.section-0.blue-gradient(row wrap)
        v-flex.px-4.text-xs-center.pt-4.max-width.mx-auto(xs12)
            a(@click="$router.push({path:'/terms'})") Termos e condições e políticas de privacidade
        v-flex.px-4.text-xs-center.pb-4.max-width.mx-auto(xs12)
            span.white--text Wappid - A solução tecnológica customizada para vencer eleições através do WhatsApp
        v-flex.text-xs-center.mx-auto.py-2(xs12 style="margin-bottom:30px;")
            img(style="max-width:250px;" src="/public/img/landing/logo-footer.png")
</template>

<script>
    export default {
        props:{
            activeNetwork: null,
            logintext: null
        },
        metaInfo (){
            return {
                title: this.$t('Wappid')
            }
        },
    }
</script>